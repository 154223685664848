import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  class: "barcard-accordion__content"
};
import { watch } from '@vue/runtime-core';
import { ref, reactive, onMounted, nextTick } from 'vue';
export default {
  __name: 'MixBarcardAccordion',
  props: {
    isMulti: Boolean,
    title: String,
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cData = reactive({
      show: props.modelValue || false
    });

    function toggle() {
      cData.show = !cData.show;
    } // 外部


    watch(function () {
      return props.modelValue;
    }, function () {
      cData.show = props.modelValue;
    }); // 內部

    watch(function () {
      return cData.show;
    }, function () {
      emit('update:modelValue', cData.show);
    });
    return function (_ctx, _cache) {
      var _component_BaseContentToggle = _resolveComponent("BaseContentToggle");

      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["barcard-accordion", [cData.show ? 'barcard-accordion_show' : '']])
      }, [__props.isMulti ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("div", {
        onClick: toggle
      }, [_renderSlot(_ctx.$slots, "title")]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_BaseContentToggle, {
        modelValue: cData.show
      }, {
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      }, 8, ["modelValue"])])], 64)) : _renderSlot(_ctx.$slots, "title", {
        key: 1
      })], 2);
    };
  }
};